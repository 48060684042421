import React from 'react';

export const WaveMarketing = () => (
  <svg width="100%" height="100%" viewBox="0 0 1728 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M170 107.306C144.248 198.146 6.98786 45.2125 0 0H1728C1724.78 7.01255 1637.11 -3.56839 1617 107.306C1601 195.5 1568.34 91.6245 1572 25.3361C1451.5 251.5 1393.38 -14.1988 1341 25.3361C1275.52 74.7547 1163 205.983 1243 107.306C1332.15 -2.6602 1082.56 106.787 1083 36C1058.29 210.085 934.5 0 895.5 79.2641C815.019 242.834 834.268 37.3349 676.5 98.6616C550.286 147.723 492.5 52.7908 492.5 25.3361C460 197 378.817 23.0911 337 66.5C192.5 216.5 212 -40.8515 170 107.306Z"
      fill="#FBFBFB"
    />
  </svg>
);
