import styled from '@emotion/styled';
import { mq } from '../../../config/styled/media';

export const PageTop = styled.div`
  position: relative;
  display: inline-block;
  margin: 78px 0 99px 0;
  display: inline-block;
  ${mq.lt.md} {
    margin: 24px 0 0 0;
  }
`;

export const IconHolder = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(80%, 50%);
  width: 570px;
  ${mq.lt.lg} {
    width: 300px;
  }
  ${mq.lt.md} {
    width: 200px;
    right: 10%;
    path {
      stroke-width: 12;
    }
  }
`;

export const Texts = styled.p`
  font-size: 3.6rem;
  line-height: 4.3rem;
  color: #ffffff;
  margin: 42px 0;
  font-weight: 400;
  ${mq.lt.lg} {
    font-size: 3rem;
    line-height: 3.4rem;
  }
  ${mq.lt.md} {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 24px 0;
  }
`;
