import React from 'react';
import { Layout } from '../components/layout';
import { MarketingTemplate } from '../components/templates/marketing';

const MarketingPage = () => {
  return (
    <Layout>
      <MarketingTemplate />
    </Layout>
  );
};

export const Head = () => <title>Bermond Management</title>;

export default MarketingPage;
