import React from 'react';

export const MarketingIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 577 146" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 121.924L35.9227 31.2981L33.039 101.233L61.1554 24.677V133.511M75.2786 103.602C76.6553 98.9746 91.9142 50.1649 99.3715 26.3385V83.7698M66.9711 75.3553L121.803 54.5855M143.404 23.846L120.142 130.187M148.527 30.4925C163.195 32.431 188.428 73.5374 148.527 76.6014C126.888 78.263 172.353 111.495 157.036 131.849M190.759 19.6921L169.158 133.511M234.791 3.90723L186.605 78.2631L216.384 140.157M247.253 15.5381L220.667 103.602M253.899 18.8613L290.454 10.5534M244.761 62.0625L281.731 56.247M227.314 107.756L277.162 98.6175M310.393 25.0923L376.856 5.56872M336.978 30.4925C335.949 43.6409 327.687 100.219 323.685 126.864M405.934 0.99939L390.979 120.218M411.749 145.142L441.327 40.1399C444.592 67.9421 456.432 122.952 456.432 120.41C456.432 117.868 464.921 43.6149 478.212 9.72268M575 23.4306C514.505 -26.3315 430.807 100.615 530.903 124.266C562.272 131.678 580.794 98.6667 567.264 65.412C546.361 71.1297 534.387 70.7856 512.69 65.412"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);
