import React from 'react';
import { WaveMarketing } from '../../icons/WaveMarketing';
import { StyledBottom, StyledContainer } from '../../../config/styled/shared';
import { Typography } from '../../../config/styled/typography';
import { MarketingIcon } from '../../icons/MarketingIcon';
import * as S from './styles';

export const MarketingTemplate = () => {
  return (
    <StyledBottom>
      <WaveMarketing />
      <StyledContainer>
        <S.PageTop>
          <Typography.XXL as="h1" responsive css={{ color: '#ffffff', zIndex: 100 }}>
            Marketing
          </Typography.XXL>
          <S.IconHolder>
            <MarketingIcon />
          </S.IconHolder>
        </S.PageTop>
        <S.Texts>
          A key tool for business development is marketing. The right marketing indicates a successful and modern
          business. The services we provide include:
          <br />
          <br />
          A marketing strategy consists of a set of goals and objectives, identifying your target audience, conducting a
          SWOT analysis, working out your competitive advantages, identifying and analyzing promotional tools, and
          finally implementing your plan.
          <br />
          <br />
          A review of your current marketing strategy: finding inconsistencies and identifying actions that can help you
          grow your business
          <br />
          <br />
          The preparation of programs to implement your marketing strategy: deciding on products, pricing, and preparing
          advertising campaigns.
        </S.Texts>
      </StyledContainer>
    </StyledBottom>
  );
};
